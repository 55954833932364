export default function setupPricesMobile(className) {
  if (window.innerWidth <= 575) {
    const spans = document.querySelectorAll(`${className} span:last-child`);
    if (!spans || !spans.length) return;

    [...spans].forEach((span) => {
      const content = span.innerHTML;
      const updatedContent = content.replace(/[-—]/g, (match) => `${match}<br>`);

      span.innerHTML = updatedContent;
    });
  }
}
