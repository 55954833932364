export default function setPopupsBehaviour(classList) {
  window.addEventListener('resize', () => {
    if (window.innerWidth > 991) {
      document.body.style.overflowY = '';
    }
  });

  const triggerClass = classList[0];
  const targetClass = classList[1];

  const triggers = document.querySelectorAll(triggerClass);
  const popups = document.querySelectorAll(targetClass);
  if (!triggers || !triggers.length || !popups || !popups.length) return;

  [...triggers].forEach((trigger) => {
    const { key } = trigger.dataset;
    if (!key) return;

    trigger.addEventListener('click', () => {
      const target = [...popups].filter((popup) => popup.dataset.key && popup.dataset.key === key);
      if (target && target.length) {
        if (window.innerWidth <= 991) {
          document.body.style.overflowY = 'hidden';
        }

        target[0].classList.add('visible');

        if (target[0].classList.contains('type-block')) {
          const wrapper = document.querySelector('.popups-grid--popups');

          if (wrapper) {
            const parent = wrapper.parentNode;

            if (parent) {
              const top = parent.offsetTop;

              window.scrollTo({ left: 0, top: top - 100, behavior: 'smooth' });
            }
          }
        }

        [...popups].forEach((popup) => {
          if (popup !== target[0]) popup.classList.remove('visible');
        });
      }
    });
  });

  [...popups].forEach((popup) => {
    const times = popup.querySelector(`${targetClass}--times`);
    if (times) {
      times.addEventListener('click', () => {
        document.body.style.overflowY = '';
        popup.classList.remove('visible');
      });
    }
  });
}
