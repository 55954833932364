function placeSelects(form) {
  const selects = form.querySelectorAll('select.site-select');
  if (!selects || !selects.length) return;

  [...selects].forEach((select) => {
    const parent = select.parentNode;
    const required = select.getAttribute('aria-required') || false;
    const name = select.getAttribute('name') || '';
    const options = select.querySelectorAll('option');

    if (!options || !options.length) {
      select.remove();

      return;
    }

    const cell = parent.parentNode;
    const nParent = document.createElement('DIV');
    nParent.setAttribute('data-name', name);
    nParent.classList.add('wpcf7-form-control-wrap');

    const element = document.createElement('DIV');
    element.classList.add('site-select');

    const classes = ['site-select-field', 'wpcf7-form-control', 'wpcf7-text', 'dark'];

    const field = document.createElement('INPUT');
    field.setAttribute('type', 'text');
    field.setAttribute('aria-required', !!required);
    field.setAttribute('aria-invalid', false);
    field.setAttribute('name', name);
    field.setAttribute('id', name);
    classes.forEach((classN) => field.classList.add(classN));
    if (required) field.classList.add('wpcf7-validates-as-required');

    const trigger = document.createElement('DIV');
    trigger.setAttribute('tabindex', Number(1));
    trigger.classList.add('site-select-trigger');

    const content = document.createElement('DIV');
    content.classList.add('site-select-content');

    const list = document.createElement('UL');
    [...options].forEach((option) => {
      const item = document.createElement('LI');
      const value = option.getAttribute('value');

      item.setAttribute('tabindex', Number(1));
      item.textContent = value;

      list.append(item);
    });
    list.classList.add('site-select-options');

    content.append(list);

    element.append(field);
    element.append(trigger);
    element.append(content);

    nParent.append(element);
    cell.append(nParent);

    parent.remove();
  });

  return 1;
}

function toggleSelect(select, collapse, selects) {
  selects.forEach((s) => s.classList.remove('collapsed'));

  if (collapse) select.classList.add('collapsed');
  if (!collapse) select.classList.remove('collapsed');
}

function setSelectValue(field, option, value, options) {
  options.forEach((o) => o.classList.remove('active'));

  option.classList.add('active');
  field.value = value;
}

function activateSelects(form) {
  const selects = form.querySelectorAll('div.site-select');
  if (!selects || !selects.length) return;

  window.addEventListener('click', (event) => {
    event.stopPropagation();

    [...selects].forEach((select) => toggleSelect(select, false, [...selects]));
  });

  [...selects].forEach((select) => {
    const field = select.querySelector('input.site-select-field');
    if (!field) return;

    const trigger = select.querySelector('div.site-select-trigger');
    if (!trigger) return;

    const options = select.querySelectorAll('ul.site-select-options li');
    if (!options || !options.length) return;

    setSelectValue(field, options[0], options[0].textContent, [...options]);

    trigger.addEventListener('click', (event) => {
      event.stopPropagation();

      const isCollapsed = select.classList.contains('collapsed');
      if (isCollapsed) toggleSelect(select, false, [...selects]);
      if (!isCollapsed) toggleSelect(select, true, [...selects]);
    });

    [...options].forEach((option) => {
      option.addEventListener('click', (event) => {
        event.stopPropagation();

        setSelectValue(field, option, option.textContent, [...options]);
        toggleSelect(select, false, [...selects]);
      });
    });
  });
}

export default function setupFormSelects(className) {
  const form = document.querySelector(className);
  if (!form) return;

  const placed = placeSelects(form);
  if (!placed) return;

  setTimeout(() => activateSelects(form));
}
