export default function setCopyLink(className) {
  const links = document.querySelectorAll(className);
  if (!links || !links.length) return;

  [...links].forEach((link) => {
    const button = link.querySelector(`${className}--button`);
    const message = link.querySelector(`${className}--message`);
    if (!button || !message) return;

    button.addEventListener('click', () => {
      const currentLocation = window.location.href;

      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(currentLocation).then(
          () => {
            message.classList.add('visible');

            setTimeout(() => message.classList.remove('visible'), 2000);
          },
          (err) => {
            alert('Failed to copy location link: ', err);
          }
        );
      }
    });
  });
}
