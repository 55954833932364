export default function setMobileBar (query) {
    const bar = document.querySelector(query);
    if (!bar) return;

    const footer = document.querySelector('.site-footer');
    if (!footer) return;

    const hero = document.querySelector('.hero-banner');
    if (!hero) return;

    const setState = () => {
        const { top } = footer.getBoundingClientRect();
        const { bottom } = hero.getBoundingClientRect();

        const wheight = window.innerHeight;

        if ((wheight <= bottom) || (top < window.innerHeight)) {
            bar.classList.remove('visible');
        } else {
            bar.classList.add('visible');
        }
    }

    setState();
    window.addEventListener('scroll', () => setState());
}
