import { Swiper, Navigation, Pagination, Autoplay } from 'swiper';

function setSlider4Height(className) {
  const existingStart = document.querySelector('.slide-copied-container');
  if (existingStart) existingStart.remove();

  const slider = document.querySelector(className);
  if (!slider) return;

  const slides = slider.querySelectorAll('.swiper-slide');

  if (slides && slides.length) {
    [...slides].forEach((slide) => {
      slide.style.height = '';
    });
  }

  if (window.innerWidth <= 991) {
    if (slides && slides.length) {
      const container = document.createElement('DIV');
      container.classList.add('site-container');
      container.classList.add('slide-copied-container');

      const inner = document.createElement('DIV');

      container.append(inner);

      [...slides].forEach((slide) => {
        const copy = slide.cloneNode(true);
        copy.classList.add('slide-copied');

        inner.append(copy);
      });

      document.body.append(container);

      const extracted = document.querySelectorAll('.slide-copied');
      if (extracted && extracted.length) {
        const sizes = [];

        [...extracted].forEach((slide) => {
          sizes.push(slide.offsetHeight);
        });

        const largets = Math.max(...sizes);

        [...slides].forEach((slide) => {
          slide.style.height = `${largets}px`;
        });

        slider.style.height = `${largets * 2 + 30}px`;
      }
    }
  } else {
    if (slides && slides.length) {
      [...slides].forEach((slide) => {
        slide.style.height = 'auto';
      });
    }

    slider.style.height = '';
  }

  const existingEnd = document.querySelector('.slide-copied-container');
  if (existingEnd) existingEnd.remove();
}

export function setPopupType3Slider(className) {
  new Swiper(className, {
    modules: [Navigation],
    spaceBetween: 12,
    loop: true,
    slidesPerView: 1,
    navigation: {
      prevEl: `${className}-prev`,
      nextEl: `${className}-next`
    }
  });
}

export function setPopupType4Slider(className) {
  new Swiper(className, {
    modules: [Navigation],
    spaceBetween: 30,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 2,
    navigation: {
      prevEl: `${className}-prev`,
      nextEl: `${className}-next`
    },
    breakpoints: {
      280: {
        direction: 'vertical'
      },
      992: {
        direction: 'horizontal',
        spaceBetween: 20
      },
      1200: {
        spaceBetween: 30
      }
    }
  });

  setSlider4Height(className);
  window.addEventListener('resize', () => setTimeout(() => setSlider4Height(className)));
}

export function setTestimonialsSlider(className) {
  new Swiper(className, {
    modules: [Autoplay, Navigation],
    spaceBetween: 30,
    loop: true,
    slidesPerView: 2,
    autoplay: {
      delay: 3000
    },
    navigation: {
      prevEl: `${className}-prev`,
      nextEl: `${className}-next`
    }
  });
}

export function setTeamSlider(className) {
  new Swiper(className, {
    modules: [Autoplay, Navigation, Pagination],
    spaceBetween: 30,
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 3000
    },
    navigation: {
      prevEl: `${className}-prev`,
      nextEl: `${className}-next`
    },
    pagination: {
      el: `${className}-pagination`,
      clickable: true
    }
  });
}

export function setResidenceSlider(className) {
  new Swiper(className, {
    modules: [Autoplay, Navigation, Pagination],
    spaceBetween: 30,
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 3000
    },
    navigation: {
      prevEl: `${className}-prev`,
      nextEl: `${className}-next`
    },
    pagination: {
      el: `${className}-pagination`,
      clickable: true
    }
  });
}

export function setNB03Slider(className) {
  new Swiper(className, {
    modules: [Autoplay, Navigation],
    spaceBetween: 15,
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 3000
    },
    navigation: {
      prevEl: `${className}-prev`,
      nextEl: `${className}-next`
    },
    breakpoints: {
      575: {
        slidesPerView: 2
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    }
  });
}

export function setNB05Slider(className) {
  new Swiper(className, {
    modules: [Autoplay, Navigation],
    spaceBetween: 15,
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 3000
    },
    navigation: {
      prevEl: `${className}-prev`,
      nextEl: `${className}-next`
    },
    breakpoints: {
      1200: {
        spaceBetween: 30
      }
    }
  });
}
