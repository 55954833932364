export default function setMobileMenu (classList) {
    const bars = document.querySelector(classList.bars);
    const times = document.querySelector(classList.times);
    const navigation = document.querySelector(classList.nav);

    window.addEventListener('resize', () => {
        if (window.innerWidth > 1199) {
            document.body.style.overflowY = '';
        }
    });

    if (bars) {
        bars.addEventListener('click', () => {
            document.body.style.overflowY = 'hidden';

            if (navigation) navigation.classList.add('visible');
        });
    }

    if (times) {
        times.addEventListener('click', () => {
            document.body.style.overflowY = '';

            if (navigation) navigation.classList.remove('visible');
        });
    }
}
