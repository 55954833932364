export default function testimonialsShowMore(className) {
  const list = document.querySelector(`${className}--list.mobile`);
  if (!list) return;

  const items = list.querySelectorAll(`${className}--item`);
  if (!items || items.length < 3) return;

  const button = list.querySelector(`${className}--list-more`);
  if (!button) return;

  [...items].forEach((item, index) => {
    if (index > 1) {
      item.classList.add('hidden');
    }
  });

  button.addEventListener('click', () => {
    [...items].forEach((item) => item.classList.remove('hidden'));
    button.classList.add('hidden');
  });
}
