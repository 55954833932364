export default function setNB04Content(className) {
    const rows = document.querySelectorAll(`${className}--row[data-role="show-more"]`);
    if (!rows || !rows.length) return;

    [...rows].forEach((row) => {
        const childs = row.querySelectorAll(`${className}--row-text > *`);
        if (!childs || !childs.length) return;

        for (let i = 2; i < childs.length; i++) {
            childs[i].classList.add('hidden');
        }

        const showMore = row.querySelector(`${className}--row-button > *[data-role="show-more"]`);
        const showLess = row.querySelector(`${className}--row-button > *[data-role="show-less"]`);

        showMore.addEventListener('click', (event) => {
            event.stopPropagation();

            for (let i = 2; i < childs.length; i++) {
                childs[i].classList.remove('hidden');
            }

            showMore.classList.add('hidden');
            showLess.classList.remove('hidden');
        });

        showLess.addEventListener('click', (event) => {
            event.stopPropagation();

            for (let i = 2; i < childs.length; i++) {
                childs[i].classList.add('hidden');
            }

            showMore.classList.remove('hidden');
            showLess.classList.add('hidden');
        });
    });
}
