import setHeaderBehaviour from './modules/header';
import setMobileMenu from './modules/mobile-menu';
import {
  setPopupType3Slider,
  setPopupType4Slider,
  setTestimonialsSlider,
  setTeamSlider,
  setResidenceSlider,
  setNB03Slider,
  setNB05Slider
} from './modules/sliders';
import setNB04Content from './modules/show-more';
import setPopupsBehaviour from './modules/popups';
import setPageUp from './modules/page-up';
import testimonialsShowMore from './modules/testimonials-show-more';
import Accordion from './modules/accordion';
import setupFormSelects from './modules/select';
import setupPricesMobile from './modules/prices';
import setCopyLink from './modules/copy-link';
import setMobileBar from './modules/mobile-bar';

import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  setHeaderBehaviour('.site-header');
  setMobileMenu({ bars: '.site-bars', times: '.site-times', nav: '.site-header--navigation' });
  setPopupType3Slider('.type-3--slider');
  setPopupType4Slider('.type-4--slider');
  setTestimonialsSlider('.testimonials--slider');
  setTeamSlider('.team--slider');
  setNB03Slider('.nb-03--slider');
  setNB05Slider('.nb-05--slider');
  setNB04Content('.nb-04');

  for (let i = 0; i < 10; i++) {
    setResidenceSlider(`.residence--slider-${i}`);
  }

  setPopupsBehaviour(['.site-popup-trigger', '.site-popup']);
  setPageUp('#site-page-up');
  testimonialsShowMore('.testimonials');

  const accordion = new Accordion('.site-accordion');
  accordion.init();

  setupFormSelects('.appointment--form');

  setupPricesMobile('.prices--list-row');

  setCopyLink('.site-copy-link');

  setMobileBar('#site-mobile-bar');
});
