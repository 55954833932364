export default function setPageUp (idName) {
    const button = document.querySelector(idName);
    if (!button) return;

    let oldValue = 0;

    window.addEventListener('scroll', () => {
        const newValue = window.pageYOffset;

        if (oldValue - newValue > 0) {
            if (window.pageYOffset > window.innerHeight) {
                button.classList.add('visible');
            } else {
                button.classList.remove('visible');
            }
        } else {
            button.classList.remove('visible');
        }

        oldValue = newValue;
    });

    button.addEventListener('click', () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
}
